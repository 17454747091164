<template>
  <div class="layout-padding min-h-screen">
    <PageTitle
      class="my-8"
      :title="content.fibPayment"
      :childOne="content.checkout"
      :childTwo="content.fib"
    />
    <div
      class="bg-white flex flex-col items-center justify-center w-full max-w-[420px] rounded-md overflow-y-auto relative"
    >
      <!-- No Payment Found -->
      <div
        v-if="
          !fibToken ||
          !fibToken.fibToken ||
          Object.keys(fibToken.fibToken).length === 0
        "
        class="text-center"
      >
        <h2 class="text-xl font-bold mb-4">
          {{ content.NoPaymentFound }}
        </h2>
        <p class="">
          {{ content.NoPaymentFoundMessage }}
        </p>
      </div>

      <!-- Main Content -->
      <div v-else class="w-full h-full">
        <!-- Title -->

        <!-- QR Code -->
        <div v-if="fibToken.fibToken.qrCode" class="flex justify-center mb-4">
          <img
            :src="fibToken.fibToken.qrCode"
            alt="QR Code"
            class="w-52 h-52 border-borderColor border-2 p-1"
          />
        </div>

        <!-- Payment Details -->
        <div class="w-full space-y-3 mb-6">
          <p v-if="fibToken.fibToken.readableCode" class="text-sm">
            <strong>{{ content.readableCode }} :</strong>
            {{ fibToken.fibToken.readableCode }}
          </p>
          <p v-if="fibToken.fibToken.validUntil" class="text-sm">
            <strong>{{ content.validUntil }} :</strong> {{ formattedTime }}
          </p>
        </div>

        <!-- App Links -->
        <div class="w-full">
          <h3 class="text-md font-semibold mb-3">{{ content.AppLinks }}</h3>
          <div class="">
            <a
              v-if="fibToken.fibToken.personalAppLink"
              :href="fibToken.fibToken.personalAppLink"
              target="_blank"
              class="flex items-center p-3 border border-cardBorder rounded-tr-md rounded-tl-md hover:bg-blueLight transition-colors"
            >
              <img src="/static/images/fib/fib.png" class="w-6 h-6 mx-3" />
              <span class=""> {{ content.personalAppLink }}</span>
            </a>

            <a
              v-if="fibToken.fibToken.businessAppLink"
              :href="fibToken.fibToken.businessAppLink"
              target="_blank"
              class="flex items-center p-3 border border-cardBorder hover:bg-blueLight transition-colors"
            >
              <img
                src="/static/images/fib/Fib-Business.webp"
                class="w-6 h-6 mx-3 rounded-md"
              />
              <span> {{ content.businessAppLink }}</span>
            </a>

            <a
              v-if="fibToken.fibToken.corporateAppLink"
              :href="fibToken.fibToken.corporateAppLink"
              target="_blank"
              class="flex items-center p-3 border border-cardBorder rounded-br-md rounded-bl-md hover:bg-blueLight transition-colors"
            >
              <img
                src="/static/images/fib/fib-con.jpg"
                class="w-6 h-6 mx-3 rounded-md"
              />
              <span> {{ content.corporateAppLink }}</span>
            </a>
          </div>
        </div>
      </div>
      <!-- Payment Detail Button -->
      <div class="mt-6" v-if="!auth && fibToken">
        <button
          @click="goToPaymentDetails"
          class="w-full p-3 bg-bluePrimary text-white font-semibold py-2 rounded-md transition-colors"
        >
          {{ content.ViewOrderDetails }}
        </button>
      </div>
    </div>
  </div>
  <div class="h-10"></div>
</template>
<script>
export default {
  name: "FibPaymentDetails",
  inject: ["content"],
  data() {
    return {
      formattedTime: "",
      countdownInterval: null,
    };
  },
  computed: {
    fibToken() {
      const modules = [
        "orders",
        "refill",
        "workshops",
        "paylater",
        "personalized",
        "subscription",
      ];

      const tokens = modules
        .map((m) => this.$store.getters[`${m}/fibToken`])
        .filter((token) => token?.fibToken?.validUntil);

      if (tokens.length === 0) return null;

      return tokens.sort(
        (a, b) =>
          new Date(b.fibToken.validUntil) - new Date(a.fibToken.validUntil)
      )[0];
    },
    auth() {
      return this.$store.getters["auth/auth"];
    },
  },
  watch: {
    fibToken: {
      immediate: true,
      handler(newVal) {
        if (newVal?.fibToken?.validUntil) {
          this.startCountdown();
        }
      },
    },
  },
  methods: {
    startCountdown() {
      if (!this.fibToken?.fibToken?.validUntil) return;

      this.stopCountdown();

      this.countdownInterval = setInterval(() => {
        const validUntil = new Date(this.fibToken.fibToken.validUntil);
        const now = new Date();
        const diff = validUntil - now;

        if (diff <= 0) {
          this.formattedTime = this.content.expired;
          this.stopCountdown();
        } else {
          const minutes = Math.floor((diff / 1000 / 60) % 60);
          const seconds = Math.floor((diff / 1000) % 60);
          this.formattedTime = `${minutes}:${seconds
            .toString()
            .padStart(2, "0")}`;
        }
      }, 1000);
    },
    stopCountdown() {
      if (this.countdownInterval) {
        clearInterval(this.countdownInterval);
        this.countdownInterval = null;
      }
    },
    goToPaymentDetails() {
      if (!this.fibToken?.uuid) return;
      this.$router.push({
        name: "orderDetails",
        params: { id: this.fibToken.uuid },
        query: { email: this.fibToken.email },
      });
    },
  },
  mounted() {
    if (this.fibToken?.fibToken?.validUntil) {
      this.startCountdown();
    }
  },
  beforeUnmount() {
    this.stopCountdown();
  },
};
</script>
